import { FooterProps } from './footer'
import { TransLink } from '@/components/trans-link'

export const _cmsMenuFields = [
  {
    name: 'title',
    label: 'Titel',
    widget: 'string',
  },
  {
    label: 'Link',
    hint: 'z.B. `/verlag`,',
    name: 'href',
    widget: 'string',
  },
]

export interface PrimaryNavEntry {
  title: string
  href: string
}

interface PrimaryNavProps {
  data: FooterProps['data']['primaryMenu']
}

export function PrimaryNav({ data }: PrimaryNavProps) {
  return (
    <nav>
      <ul>{data.map(renderLi)}</ul>

      <style jsx>{`
        nav {
          margin-top: 1rem;
        }
        ul {
          list-style: none;
          padding: 0;
          margin-top: 0;
        }
      `}</style>
    </nav>
  )

  function renderLi({ title, href }: PrimaryNavEntry) {
    const isTopLink = href === '#'
    return (
      <li key={href}>
        <TransLink
          href={href}
          className={'switched' + (isTopLink ? ' toplink' : '')}
        >
          {title}
        </TransLink>
        <style jsx>{`
          li {
            font-family: var(--font-sans);
            font-weight: bold;
            display: inline-block;
            margin: 0 0 0 1em;
          }
          a {
            padding: 0.3rem 0;
            border: none;

            &.toplink {
              display: none;
              @media (min-width: 700px) {
                display: block;
              }
            }
          }
        `}</style>
      </li>
    )
  }
}
