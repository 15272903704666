import { AuthorCard, AuthorData } from '../author-card'

export const _cmsAuthorsTeaser = {
  label: 'Author*innenvorschau',
  name: 'AuthorsTeaser',
  widget: 'object',
  summary: 'Alle',
  fields: [
    {
      label: 'placeholder',
      name: 'placeholder',
      widget: 'hidden',
      default: '',
    },
  ],
}

export interface AuthorsTeaserProps {
  type?: 'AuthorsTeaser'
  allAuthors?: AuthorData[]
}

export function AuthorsTeaser({ allAuthors }: AuthorsTeaserProps) {
  if (!allAuthors) return null

  // are they sorted?
  // TODO: layout when more than 3 authors

  return (
    <div className="side-by-side">
      {allAuthors.map((author) => {
        return author !== undefined ? (
          <AuthorCard key={author.fullName} data={author} />
        ) : null
      })}

      <style jsx global>{`
        .side-by-side {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 6rem;

          article {
            flex-basis: 100%;

            @media (min-width: 780px) {
              max-width: 47%;
              &:nth-child(odd) {
                margin-top: 18vh;
              }
            }
            @media (min-width: 1280px) {
              &:nth-child(3n + 1) {
                margin-top: 22vh;
              }
              &:nth-child(3n + 2) {
                margin-top: 0;
              }
              &:nth-child(3n + 3) {
                margin-top: 15vh;
              }
            }
          }
        }
      `}</style>
      <style jsx global>{`
        .side-by-side {
          article {
            @media (min-width: 1280px) {
              max-width: ${allAuthors.length < 3 ? '47%' : '28%'};
            }
          }
        }
      `}</style>
    </div>
  )
}
