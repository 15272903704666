import { BookCard, BookData } from '@/components/book-card'

export const _cmsBooksTeaser = {
  label: 'Büchervorschau',
  name: 'BooksTeaser',
  widget: 'object',
  summary: 'Büchervorschau',
  fields: [
    {
      label: 'Bücher',
      name: 'books',
      widget: 'relation',
      multiple: true,
      min: 1,
      max: 3,
      collection: 'books',
      search_fields: ['title'],
      value_field: '{{slug}}',
    },
  ],
}

export interface BooksTeaserProps {
  type?: 'BooksTeaser'
  books: string[]
  allBooks?: BookData[]
}

export function BooksTeaser({ books, allBooks }: BooksTeaserProps) {
  if (!allBooks) return null

  const selectionWithData = books.map((slug) => {
    return allBooks.find((book) => book.slug === slug)
  })

  return (
    <div className="side-by-side" id="📖">
      {selectionWithData.map((book) => {
        return book !== undefined ? (
          <BookCard key={book.title} data={book} />
        ) : null
      })}

      <style jsx global>{`
        .side-by-side {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 6rem;

          article {
            flex-basis: 100%;

            @media (min-width: 780px) {
              max-width: 47%;
              &:nth-child(odd) {
                margin-top: 18vh;
              }
            }
            @media (min-width: 1280px) {
              &:nth-child(3n + 1) {
                margin-top: 22vh;
              }
              &:nth-child(3n + 2) {
                margin-top: 0;
              }
              &:nth-child(3n + 3) {
                margin-top: 15vh;
              }
            }
          }
        }
      `}</style>
      <style jsx global>{`
        .side-by-side {
          article {
            @media (min-width: 1280px) {
              max-width: ${books.length < 3 ? '47%' : '28%'};
            }
          }
        }
      `}</style>
    </div>
  )
}
