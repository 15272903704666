export const _cmsBigQuote = {
  label: 'Großes Zitat',
  name: 'BigQuote',
  widget: 'object',
  summary: '{{fields.quote}}',
  fields: [
    {
      label: 'Zitat',
      name: 'quote',
      widget: 'string',
    },
    {
      label: 'Autor*in',
      name: 'author',
      widget: 'string',
      required: false,
    },
    {
      label: 'Title',
      name: 'title',
      widget: 'string',
      required: false,
    },
  ],
}

export interface BigQuoteProps {
  title: string
  author: string
  quote: string
  type?: 'BigQuote'
}

export function BigQuote({ title, author, quote }: BigQuoteProps) {
  return (
    <blockquote>
      <p
        className="big-font"
        style={{ marginTop: '25vh', marginBottom: '2rem' }}
      >
        {quote}
      </p>
      <footer>
        <cite>
          <b>{title}</b>
          <br />
          {author}
        </cite>
      </footer>
    </blockquote>
  )
}
