import { AuthorData } from '../author-card'
import { BlueImage, ImageProps } from '../blue-image'
import { BookData } from '../book-card'
import {
  AuthorsTeaser,
  AuthorsTeaserProps,
  _cmsAuthorsTeaser,
} from './authors-teaser'
import { BigQuote, BigQuoteProps, _cmsBigQuote } from './big-quote'
import { BooksTeaser, BooksTeaserProps, _cmsBooksTeaser } from './books-teaser'
import {
  GradientBlock,
  GradientBlockProps,
  _cmsGradientBlock,
} from './gradient-block'
import { Spacer, SpacerProps, _cmsSpacer } from './spacer'
import { TextBlock, TextBlockProps, _cmsTextBlock } from './text-block'

export const _cmsPageBlocks = {
  label: 'Abschnitte',
  name: 'blocks',
  widget: 'list',
  types: [
    _cmsSpacer,
    _cmsBigQuote,
    _cmsGradientBlock,
    _cmsTextBlock,
    _cmsBooksTeaser,
    _cmsAuthorsTeaser,
  ],
}

export type Block =
  | BigQuoteProps
  | SpacerProps
  | GradientBlockProps
  | BooksTeaserProps
  | TextBlockProps
  | ImageProps
  | AuthorsTeaserProps

export type Blocks = Block[]

export type ImageColumnBlock = SpacerProps | GradientBlockProps | ImageProps

export interface PageBlocksProps {
  blocks: Blocks
  books?: BookData[]
  authors?: AuthorData[]
}

export function PageBlocks({ blocks, books, authors }: PageBlocksProps) {
  if (!blocks) return null
  const comps = blocks.map((block, i) => {
    switch (block.type) {
      case 'Spacer':
        return <Spacer key={i} {...block} />
      case 'BigQuote':
        return <BigQuote key={i} {...block} />
      case 'GradientBlock':
        return <GradientBlock key={i} {...block} />
      case 'BooksTeaser':
        return <BooksTeaser key={i} {...block} allBooks={books} />
      case 'TextBlock':
        return <TextBlock key={i} {...block} />
      case 'BlueImage':
        return <BlueImage key={i} {...block} />
      case 'AuthorsTeaser':
        return <AuthorsTeaser key={i} {...block} allAuthors={authors} />
    }
    return null
  })
  return <>{comps}</>
}
