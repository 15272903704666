import { MDXRemote } from 'next-mdx-remote'

import { BlueImage } from './blue-image'
import { BookData } from './book-card'
import { TransLink } from '@/components/trans-link'
import { SerializedResult } from '@/types'

export interface AuthorData {
  slug: string
  fullName: string
  name: string
  description?: string | SerializedResult
  imageSrc?: string
  width?: number
  height?: number
  books?: BookData[]
}

export function AuthorCard({ data }: { data: AuthorData }) {
  const { fullName, description, slug, imageSrc, width, height } = data
  const { compiledSource } = description as SerializedResult

  return (
    <article>
      {imageSrc ? (
        <BlueImage
          alt={'Portrait von ' + fullName}
          imageSrc={imageSrc}
          blue
          width={width}
          height={height}
        />
      ) : null}

      <h3>
        <TransLink href={`authors/${slug}`}>{fullName}</TransLink>
      </h3>

      <p className="serif">
        <MDXRemote compiledSource={compiledSource} />
      </p>
      <style jsx>
        {`
          article {
            h3 {
              margin-top: 1.2rem;
              margin-bottom: 2rem;
            }
            :global(a) {
              border: 0;
              transition: 0.4s color;
              &:hover {
                color: var(--brand);
              }
            }
          }
        `}
      </style>
    </article>
  )
}
