import { useRouter } from 'next/router'

interface TransLinkProps {
  children: JSX.Element | string
  href: string
  className?: string
}

export const TransLink = ({ children, href, className }: TransLinkProps) => {
  const router = useRouter()

  function onLinkClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (window.location.href === href || window.location.pathname === href) {
      e.preventDefault()
      return false
    }

    const isAnchor = href.startsWith('#') || href.startsWith('/#')
    const isHome = window.location.pathname === '/'

    if (isHome && isAnchor) return true

    e.preventDefault()
    document.body.classList.add('fade-out')
    setTimeout(() => {
      void router.push(href)
    }, 400)
  }

  const prefetch = () => {
    void router.prefetch(href)
  }

  return (
    <a
      className={className}
      href={href}
      onClick={onLinkClick}
      onMouseEnter={prefetch}
      onPointerEnter={prefetch}
    >
      {children}
    </a>
  )
}

/*
export function useTransition(href: string) {
  const transition = function () {
    prefetch()

    void router.replace(href, undefined, {
        shallow: true,
      })
router.push(href)
    router.replace(window.location.href + '#l', undefined, {
      shallow: true,
    })
  }

  return { transition, prefetch }
}*/
