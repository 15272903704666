import { MDXRemote } from 'next-mdx-remote'

import { TransLink } from '@/components/trans-link'
import { SerializedResult } from '@/types'

export interface BookData {
  title: string
  slug: string
  description: SerializedResult
  shortDescription?: SerializedResult
  imageSrc: string
  width?: number
  height?: number
  author: {
    fullName: string
    slug: string
  }
}

export function BookCard({ data }: { data: BookData }) {
  const { title, shortDescription, description, slug } = data
  const serializedText = shortDescription ? shortDescription : description
  return (
    <article>
      <h3>
        <TransLink href={`books/${slug}`}>{title}</TransLink>
      </h3>
      <p className="serif">
        <MDXRemote {...serializedText} />
      </p>
      <style jsx>
        {`
          article {
            &:before {
              content: ' ';
              display: block;
              background-color: var(--brand);
              height: 0.5rem;
              width: 4.7rem;
              margin-top: 4rem;
            }
            h3 {
              margin-top: 0.8rem;
              margin-bottom: 2rem;
            }
            :global(a) {
              border: 0;
              transition: 0.4s color;
              &:hover {
                color: var(--brand);
              }
            }
          }
        `}
      </style>
    </article>
  )
}
