export const _cmsGradientBlock = {
  label: 'Blaues Rechteck',
  name: 'GradientBlock',
  widget: 'object',
  summary: '',
  fields: [
    {
      label: 'Höhe',
      hint: '(in Prozent der Seitenhöhe)',
      name: 'height',
      widget: 'string',
      value_type: 'int',
      min: 10,
      max: 100,
      step: 10,
      required: false,
    },
  ],
}

export interface GradientBlockProps {
  type?: 'GradientBlock'
  height?: string
}

export function GradientBlock({ height = '60' }: GradientBlockProps) {
  return (
    <div className="gradient-block">
      &nbsp;
      <style jsx>{`
        .gradient-block {
          margin-top: 10rem;
          margin-bottom: 3rem;
          width: 100%;
          height: ${height + 'vh'};
          background-image: var(--bg-gradient);
        }
      `}</style>
    </div>
  )
}
