import { MDXRemote } from 'next-mdx-remote'

import { BlueImage } from '../blue-image'
import { SerializedResult } from '@/types'

export const _cmsTextBlock = {
  label: 'Textblock',
  name: 'TextBlock',
  widget: 'object',
  summary: '{{fields.body}}',
  fields: [
    {
      label: 'Inhalt',
      name: 'body',
      widget: 'markdown',
    },
    {
      label: 'Maximale Breite',
      hint: '',
      name: 'maxWidth',
      widget: 'select',
      options: [
        { label: 'Volle Breite', value: 100 },
        { label: 'Halbe Breite', value: 50 },
        { label: '1/3 Breite', value: 33 },
      ],
      default: { label: 'Halbe Breite', value: 50 },
      required: false,
    },
  ],
}

export interface TextBlockProps {
  type?: 'TextBlock'
  body: string | SerializedResult
  maxWidth?: {
    label: string
    value: number
  }
}

const maxWidthDefault = {
  label: '',
  value: 50,
}

export function TextBlock({
  body,
  maxWidth = maxWidthDefault,
}: TextBlockProps) {
  const { compiledSource } = body as SerializedResult

  const _maxWidth = maxWidth ? maxWidth : maxWidthDefault

  const maxWidthString = `${_maxWidth.value - 10}vw` //minus page margins

  return (
    <section style={{ maxWidth: maxWidthString }}>
      <MDXRemote
        compiledSource={compiledSource}
        components={{
          img: BlueImage,
        }}
      />
      <style jsx>
        {`
          section {
            margin: 4rem 0;
          }
        `}
      </style>
    </section>
  )
}
