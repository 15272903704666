export const _cmsSpacer = {
  label: 'Abstandshalter',
  name: 'Spacer',
  widget: 'object',
  summary: '{{fields.height}}%',
  fields: [
    {
      label: 'Höhe',
      hint: '(in Prozent der Seitenhöhe)',
      name: 'height',
      widget: 'string',
      value_type: 'int',
      min: 10,
      max: 100,
      step: 10,
      required: false,
    },
  ],
}

export interface SpacerProps {
  type?: 'Spacer'
  height?: string
}

export function Spacer({ height = '40' }: SpacerProps) {
  return (
    <div className="spacer">
      &nbsp;
      <style jsx>{`
        .spacer {
          height: ${height + 'vh'};
        }
      `}</style>
    </div>
  )
}
