export const _cmsImage = {
  label: 'Bild',
  name: 'BlueImage',
  widget: 'object',
  summary: 'Bild {{fields.alt}}',
  required: false,
  fields: [
    {
      label: 'Bild',
      name: 'imageSrc',
      widget: 'image',
    },
    {
      label: 'Alternativtext',
      name: 'alt',
      hint: 'Wird statt dem Bild benutzt von z.B. Screenreadern oder Suchmaschinen.',
      widget: 'string',
    },
    {
      label: 'Blaufilter',
      name: 'blue',
      widget: 'boolean',
      default: true,
    },
    {
      label: 'Maximale Breite',
      hint: 'in Prozent',
      name: 'maxWidth',
      widget: 'select',
      options: ['50', '100'],
      default: '50',
      required: false,
    },
  ],
}

export interface ImageProps {
  type?: 'BlueImage'
  imageSrc: string
  alt?: string
  blue?: boolean
  width?: number
  height?: number
  maxWidth?: string
  className?: string
}

const sizes = [1800, 900, 600, 300]
const formats = ['avif', 'webp', 'jpeg']

export function BlueImage({
  imageSrc,
  alt,
  blue = true,
  className,
  maxWidth = '50',
  width,
  height,
}: ImageProps) {
  return (
    <div
      className={`img-wrapper ${className ?? ''} ${blue ? 'blue' : ''}`}
      style={{ maxWidth: maxWidth + '%' }}
    >
      {renderImage()}
      <style jsx global>{`
        .img-wrapper {
          img {
            max-width: 100%;
            height: auto;
          }

          &.blue {
            background-color: var(--brand);
            img {
              filter: grayscale(100%);
              mix-blend-mode: luminosity;
              opacity: 0.7;
            }
          }
        }
      `}</style>
    </div>
  )

  function renderImage() {
    const fileName = imageSrc.substring(0, imageSrc.lastIndexOf('.'))

    const srcBase = '/' + fileName

    return (
      <picture>
        {formats.map((format) => {
          const ext = format === 'jpeg' ? 'jpg' : format

          return (
            <source
              key={format}
              type={'image/' + format}
              srcSet={`${srcBase}-${sizes[0]}.${ext} ${sizes[0]}w, ${srcBase}-${sizes[1]}.${ext} ${sizes[1]}w, ${srcBase}-${sizes[2]}.${ext} ${sizes[2]}w, ${srcBase}-${sizes[3]}.${ext} ${sizes[3]}w`}
              sizes={`(max-width: ${sizes[1]}px) 100vw, ${sizes[1]}px`}
            />
          )
        })}
        <img
          loading="lazy"
          decoding="async"
          width={width}
          height={height}
          src={`${srcBase}-${sizes[0]}.jpg`}
          alt={alt ?? 'Bild'}
        />
      </picture>
    )
  }
}
